import request from '@/utils/request'

// 一级-车辆信息-查询
export function carinfo_query(data) {
    return request({
        url: '/platform_api/manage_api/car_manage/query',
        method: 'post',
        data: data
    })
}

// 一级-车辆品牌信息-查询
export function car_brand_get(data) {
    return request({
        url: '/platform_api/manage_api/carlib/brand/query',
        method: 'post',
        data: data
    })
}
// 一级-车辆品牌信息-创建
export function car_brand_create(data) {
    return request({
        url: '/platform_api/manage_api/carlib/brand/create',
        method: 'post',
        data: data
    })
}
// 一级-车辆品牌信息-编辑
export function car_brand_update(data) {
    return request({
        url: '/platform_api/manage_api/carlib/brand/edit',
        method: 'post',
        data: data
    })
}
// 一级-车辆品牌信息-删除
export function car_brand_delete(data) {
    return request({
        url: '/platform_api/manage_api/carlib/brand/delete',
        method: 'post',
        data: data
    })
}